import React from 'react'

const LineaInicio2 = () => {
  return (
    <div className='linea-inicio2'>
      
    </div>
  )
}

export default LineaInicio2
