import React from 'react'
import hoja3dep from '../img/DEP450-hoja3.png'

const Info3DEP450 = () => {
  return (
    <div className="info-foto">
      <img src={hoja3dep} width="77.5%" alt=""></img>
    </div>
  )
}

export default Info3DEP450
