import React from 'react'

const LineaInicio = () => {
  return (
    <div className='linea-inicio'>
      
    </div>
  )
}

export default LineaInicio
