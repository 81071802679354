import React from 'react'
import hoja2dep from '../img/DEP450-hoja2-5.png'

const Info2DEP450 = () => {
  return (
    <div className="info-foto">
      <img src={hoja2dep} width="77.5%" alt=""></img>
    </div>
  )
}

export default Info2DEP450
