import React from 'react'
import hoja2 from '../img/rsz_ep450-hoja2-celulares.png'

const Info2EP4503 = () => {
  return (
    <div className="info-foto3">
      <img src={hoja2} width="100%" alt=""></img>
    </div>
  )
}

export default Info2EP4503
