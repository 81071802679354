import React, { useState } from 'react'
import movistar_logo from '../img/movistar-arena-borde-blanco2.png'
import lollapalooza_logo from '../img/Lollapalooza.png'
import viña_logo from '../img/Viña.png'
import belive_logo from '../img/Be live.png'
import banqueteria_logo from '../img/Banquetería.png'
import primavera_logo from '../img/primavera-sound-gris.png'
import seguridad_logo from '../img/Seguridad.png'

// Estilos
import '../css/Hero.css'

const Hero = () => {

    const [isLogoVisible, setIsLogoVisible] = useState(false);

    const handleMouseEnter = () => {
      setIsLogoVisible(true);
    };
  
    const handleMouseLeave = () => {
      setIsLogoVisible(true);
    };

    return (
        <div className='hero' id="#hero" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div class="hero-content">
                <h1>Bymas | Radios Motorola</h1>
                <p className='poppins-medium'>Venta y arriendo de equipos de radio comunicación al mejor precio</p>
            </div>
            <div className={`marcas-logos ${isLogoVisible ? 'visible' : ''}`}>
                <img className="movistar-arena" src={movistar_logo}/>
                {/*
                <img src={banqueteria_logo} width="4%"/>
                <img src={belive_logo} width="4%"/>
                */}
                <img className="viña" src={viña_logo}/>
                {/*<img src={seguridad_logo} width="4%"/>*/}
                <img className="lollapalooza" src={lollapalooza_logo}/>
                <img className="primavera-sound" src={primavera_logo}/>
            </div>
            <div className='franja-diagonal'></div>
        </div>
    )
}

export default Hero
