import React from 'react'
import hoja4dep from '../img/rsz_dep450-hoja4.png'

const Info4DEP4503 = () => {
  return (
    <div className="info-foto3">
      <img src={hoja4dep} width="100%" alt=""></img>
    </div>
  )
}

export default Info4DEP4503
